import { useState } from 'react'
import { Action } from 'src/clients'
import {
  AgentManagementHeading,
  AgentManagementTable,
} from 'src/components/AgentManagementTable'
import { useProfile } from 'src/hooks'
import { hasPermission } from 'src/utils/auth'

export function AgentUserManagement(): JSX.Element {
  const { profileData, isGetProfileLoading } = useProfile()
  const [search, setSearch] = useState<{ search: string; filter: string }>({
    search: '',
    filter: '',
  })

  const handleFiltersChange = (searchText: string, filter: string) => {
    setSearch({ search: searchText, filter: filter })
  }

  return (
    <>
      <AgentManagementHeading
        canCreateAgent={!isGetProfileLoading && 
          profileData !== undefined &&
          hasPermission(profileData.userType, Action.CREATE)}
        onFilterChange={handleFiltersChange}
      />
      <AgentManagementTable
        currentSessionUser={profileData}
        isGetProfileLoading={isGetProfileLoading}
        filter={search.filter}
        search={search.search}
      />
    </>
  )
}

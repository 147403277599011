import { useQuery } from 'react-query'
import { ApiClient } from '../../clients'
import { useConfig } from '../config'

export function useGetAllAgents() {
  const config = useConfig()
  const apiClient = new ApiClient(config)

  const { isLoading, data, isError, refetch } = useQuery(
    ['agents'],
    async () => {
      return await apiClient.getAllAgents()
    }
  )

  return {
    allAgentsData: data,
    isAgentsLoading: isLoading,
    isAgentsError: isError,
    refetchAllAgentsData: refetch,
  }
}
